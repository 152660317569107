/**
 * @version 2.1 special version modified to work without any ES6 feature
 * @author Mahmoud Al-Refaai <Schuttelaar & Partners>
 */

/**
 * By default, this constructor takes no parameters and set its queryString directly from URL.
 * Optionally, a custom queryString can be given als parameter.
 * @param {String} customQueryString if this is given, all the functions will manipulate this string.
 * @param {bool}   autoUpdate        update the current window's URL after each modification.
 */
export default function QueryString(customQueryString, autoUpdate) {

    // --------------- [Initiate queryString obj] --------------- //

    //attributes
    var thisQueryString;
    var thisAutoUpdate = autoUpdate; 

    if(customQueryString) {
        thisQueryString = customQueryString;
        if (autoUpdate == null) thisAutoUpdate = false;
    } else {
        thisQueryString = getWindowQueryString();
        if (autoUpdate == null) thisAutoUpdate = true;
    }

    // ------------------ [Getters and Setters] ------------------ //
    /**
     * By default, return this is the getter of thisQueryString attribute.
     * If the key is given, then return the first value of this key.
     * @param {String} key (optical)
     */
    function get(key) {
        if(key) return getParamValue(key);
        return thisQueryString;
    }
    function set(queryString) {
        thisQueryString = queryString;
        if(thisAutoUpdate) updateQueryString();
    }
    function getAutoUpdate() {
        return thisAutoUpdate;
    }
    function setAutoUpdate(boolean) {
        if (typeof boolean == 'boolean') thisAutoUpdate = boolean;
    }

    // ---------------[QueryString functions]--------------- //

    /**
     * get the whole string after "?" from the current window's URL, 
     * update "thisQueryString" and return it.
     * @return {String} the query string
     */
    function getWindowQueryString() {
        thisQueryString = window.location.search.substr(1);
        return thisQueryString;
    }
    
    /**
     * Update the current window's URL with "thisQueryString"
     */
    function updateQueryString() {
        let url = window.location.href;
        let urlParts = url.split('?');

        if (urlParts.length > 0) {
            let updatedURL = urlParts[0] + '?' + thisQueryString;
            window.history.replaceState({}, document.title, updatedURL);
            return true;
        } else {
            return false;
        }
    }

    // ---------------[Params functions]--------------- //

    /**
     * get the first value of the given parameter's key.
     * @param {String} key the parameter's key to look for.
     * @return {String} if getAll set to false, return the first value of the given key.
     */
    function getParamValue(key) {
        let paramList = thisQueryString.split('&');
        for (let i = 0;  i < paramList.length; i++) {
            let param = paramList[i].split('=');
            if (param[0] == key) {
                return param[1];
            }
        }
        return '';
    }

    /**
     * get a list of all values that corresponds to the given parameter's key.
     * @param {String} key the parameter's key to look for.
     * @return {Array} if getAll set to true, return list of all values of the given key.
     */
    function getAllParamValues (key) {
        let paramList = thisQueryString.split('&');
        let valueList = [];
        for (let i = 0;  i < paramList.length; i++) {
            let param = paramList[i].split('=');
            if (param[0] == key) {
                valueList.push(param[1]);
            }
        }
        return valueList;
    }

    /**
     * get list of all dates from "thisQueryString". The dates paramter should be like: [ dateParamKey=VALUE ].
     * The resulted list is ASC sorted.
     * @param {String} dateParamKey the parameter's key of the dates (default => "dates[]").
     * @return {Array} array of date-strings
     */
    function getDateList(dateParamKey = 'dates[]') {
        let dateList = getAllParamValues(dateParamKey);

        //sort the date ASC
        dateList.sort(function(a,b) {
            // Turn strings into dates, and then subtract them 
            // return (negative | positive | 0)
            return (new Date(a) - new Date(b));
        });
        return dateList;
    }

    /**
     * Replace the value of the given parameter's key.
     * If this key does not exist, append a new parameter to "thisQueryString".
     * @param {String} key
     * @param {String} value 
     * @return {String} thisQueryString after modification
     */
    function updateParam (key='', value='') {
        var regex = new RegExp(`(&|^)?(${key}=.*?)($|&)`, 'g');

       if(thisQueryString.match(regex)){
           // it is existed, so update the value
            thisQueryString = thisQueryString.replace(regex, `$1${key}=${value}$3`)
        } else {
            // append
            let prefix = !thisQueryString.length? '' : '&';
            thisQueryString += prefix + key + '=' + value;
        }

        if(thisAutoUpdate) updateQueryString();
        return thisQueryString;
    }

    /**
     * append a new parameter to "thisQueryString" (even if the key is already existed).
     * @param {String} key
     * @param {String} value 
     * @return {String} thisQueryString after modification
     */
    function appendParam(key, value='') {
        if(!key) return;

        let prefix = !thisQueryString.length? '' : '&';
        thisQueryString += prefix + key + '=' + value;

        if(thisAutoUpdate) updateQueryString();
        return thisQueryString;
    }

    /**
     * escape all special characters of regex expressions.
     * @param {String} str 
     * @return {String} escaped string that ready to be used in regex expressions.
     */
    function escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    }

    /**
     * remove the given query parameter [ KEY=VALUE ] from "thisQueryString".
     * If the value is not set, then remove all parameters matches the given key.
     * @param {String} key
     * @param {String} value (optional attribute) 
     * @param {String} needEscape set to false, so you can pass regex expression as a string! (eg. "^[&]*")
     * @return {String} thisQueryString after modification
     */
    function removeParam(key='', value, needEscape = true) {
        if (needEscape) {
            key = escapeRegExp(key);
            if (value) value = escapeRegExp(value);
        }

        //if there the value is not set, then remove all parameters matches the given key
        if(!value) value = '[^&]*';

        //NOTE: URLSearchParams() is an option but it is not compatible with IE, so I didn't use it.
        var regex = new RegExp(`(&)?(${key}=${value})`, `g`);
        thisQueryString = thisQueryString.replace(regex, ``);
        thisQueryString = thisQueryString.replace(/^[&]*/,``);

        if(thisAutoUpdate) {
            updateQueryString();
        };
        return thisQueryString;
    }

    /**
     * Check whether thisQueryString has the given paramter [ key=value ]
     * If the value is not set, then check whether any parameter has the given key.
     * @param {String} key 
     * @param {String} value (optional attribute) 
     * @param {bool} needEscape set to false, so you can pass regex expression as a string! (eg. "^[&]*")
     * @returns {bool} bool wether thisQueryString has the given paramter
     */
    function hasParam(key, value, needEscape = true) {
        if (needEscape) {
            key = escapeRegExp(key);
            if (value) value = escapeRegExp(value);
        }
        //if there the value is not set, then remove all parameters matches the given key
        if(!value) value = '[^&]*';

        var regex = new RegExp(`(&)?(${key}=${value})`, `g`);
        return thisQueryString.match(regex);
    }

    /**
     * Append/Remove the given paramter from thisQueryString
     * @param {String} key 
     * @param {String} value
     * @return {String} thisQueryString after modification 
     */
    function toggleParam(key, value) {
        if(hasParam(key, value)) {
            removeParam (key, value);
        } else {
            appendParam(key, value);
        }
    }

    // ------------------ [Module Interface] ------------------ //

    return {
        getWindowQueryString : getWindowQueryString,
        updateQueryString : updateQueryString,

        getParamValue : getParamValue,
        getAllParamValues : getAllParamValues,
        getAll : getAllParamValues,

        getDateList : getDateList,

        updateParam : updateParam,
        appendParam : appendParam,
        removeParam : removeParam,
        hadParam : hasParam,
        toggleParam : toggleParam,

        get : get,
        set : set,
        getAutoUpdate : getAutoUpdate,
        setAutoUpdate : setAutoUpdate
    }
};
