import utils from './utils';

export default class CookieConsent {
    constructor(analytics, analyticsId) {
        // cookie bar consent
        $('[data-cookie-consent] .cookies-yes').on('click', (e) => {
            e.preventDefault();
            $('[data-cookie-consent]').css({ opacity: 0 });
            setTimeout(() => {
                $('[data-cookie-consent]').addClass('hide');
            }, 600);
            utils.setCookie('cookie-consent', 1);
            return false;
        });

        $('[data-cookie-consent] .cookies-no').on('click', (e) => {
            e.preventDefault();
            $('[data-cookie-consent]').css({ opacity: 0 });
            setTimeout(() => {
                $('[data-cookie-consent]').addClass('hide');
            }, 600);
            utils.setOneDayCookie('cookie-refuse', 1);
            return false;
        });

        if (!utils.getCookie('cookie-consent') && !utils.getCookie('cookie-refuse')) {
            // Show message about cookies and let user choose to accept or refuse
            $('[data-cookie-consent]').removeClass('hide');
        } else if (utils.getCookie('cookie-consent')) {
            switch (analytics) {
                case 'ga':
                    // During start up or at demo/test/dev enviroment there's no need to add an analyticsId
                    if (analyticsId) {
                        // First add script to Google Tags manager
                        const googleTagManagerUrl = 'https://www.googletagmanager.com/gtag/js?id=' + analyticsId;
                        let script = document.createElement('script');
                        script.onload = () => {
                            // When this is loaded, initialise GA
                            this.initGA(analyticsId);
                        }
                        script.src = googleTagManagerUrl;
                        document.head.appendChild(script);
                    }
                    break;
                default:
                    break;
            }
        }
    }
    initGA(id) {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', id);
    }
}