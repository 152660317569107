import '../scss/index.scss';

import jQuery from 'jquery';
import Utils from './utils';
import screenDetector from './screen-detector'; // initializes screensize etc.
import CookieConsent from './CookieConsent';
import './forms';
import './home';
import './news';

//const urlParams = Utils.getQueryStringParams(window.location.search);


import SVGInliner from 'svg-inliner';

$(document).ready(() => {

    if (typeof(gaCode) !== 'undefined') {
        new CookieConsent('ga', gaCode); //gaCode is set in Layout/cookie-bar.html.twig
    }

    new SVGInliner(document.querySelectorAll(".svg-inline"));

    $('#navbar-toggle').on('click', () => {
        $('nav.nav-top').toggleClass('open');
    })
});

