$(document).ready(() => {
    $('input[required], textarea[required]').on('input', function() {
        $error = $(this).parent().find('.error');
        if (this.validity.valid) {
            $error.hide();
        } else {
            $error.show();
        }
    });

    $('.form input[type=submit]').on('click', function() {
        let formValid = true;
        $('input[required], textarea[required]').each((index, element) => {
            $error = $(element).parent().find('.error');
            if (element.validity.valid) {
                $error.hide();
            } else {
                $error.show();
                formValid = false;
            }
        });
        return formValid;
    });

    $('.contact-form input[name="lastname"]').val('v' + (Math.random() * 42));
});