var hiddenLogos = Array();
var lastRandomNumber = -1;

$(document).ready(() => {
	loadLogos();

	setInterval(function() {
		fadeLogos();
	}, 3600);
});

/**
 * Load logos on homepage.
*/
var loadLogos = function() {

	//randomized array with DOM-elements
	hiddenLogos = shuffle(jQuery.makeArray($('.participant-store a')));

	//move logos to slots
	var slots = jQuery.makeArray($('.participant-logos .logo'));

	for(var i in slots) {
		var $slot = $(slots[i]);
		var $logo = hiddenLogos.shift();
		$slot.html($logo);
	}
}

/**
 * Shuffle array using Fisher-Yates algorithm
*/
function shuffle(array) {
	var currentIndex = array.length, temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {

		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}

/**
 * Fade a logo out and another one in.
*/
var fadeLogos = function() {

	var i = getDifferentNumber();

	var $logo1 = $('.participant-logos .logo:eq(' + i + ') a');	
	var $slot = $logo1.parent();
	$logo1.css('opacity', 0);
	setTimeout(function() {
		$slot.empty();
		hiddenLogos.push($logo1);
		var $logo2 = $(hiddenLogos.shift());
		$slot.append($logo2);
		$logo2.css('opacity', 0);
		setTimeout(function() {
			$logo2.css('opacity', 1);
		}, 10);
	}, 1000);
}

/**
 * Get a semi-random number that is different from the last number.
*/
var getDifferentNumber = function() {
	var max = $('.participant-logos .logo').length;
	var i = Math.floor(Math.random() * max);
	if(i == lastRandomNumber) {
		i++;
	}

	if(i >= max) {
		i = 0;
	}
	lastRandomNumber = i;
	return i;
}

