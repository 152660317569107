import QueryString from './query-string.es5.module';

$(document).ready(() => {
	if ($('body.news').length == 0) {
		return
	}
	
	//initiate query string instance
	let queryString = new QueryString();

	//check url and update filter check
	let filter = queryString.get('filter')
	
	if(filter == "news") 
		$('.radio-input-container > span#news > input').attr('checked', true);
	else if (filter == "meeting")
		$('.radio-input-container > span#meeting > input').attr('checked', true);

	$('.radio-input-container > span').click((e) => {
		queryString.updateParam('filter', $(e.currentTarget).attr('id'))
		queryString.removeParam('page')
		location.reload()
	})

	//auto scroll if the "Volgende" or "Vorige" buttons is clicked
	if(queryString.get('scroll') == 1) {
		var element = document.getElementById("filter-container");
		element.scrollIntoView();
        window.scrollBy(0, -130);
	}
});